.dashling-container {
  margin-right: -1rem;
}
.dashling-container .dashling {
  padding-right: 1rem;
  width: 100%;
}
@media (min-width: 768px) {
  .dashling-container .dashling {
    width: 50%;
  }
}
.dashling-container .dashling.dashling--double {
  width: 100%;
}
.dashling-container .dashlingsizer {
  width: 50%;
}
