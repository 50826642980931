@tailwind components;

@layer components {
  /* INPUT-GROUP COMPONENT > <Button> styles */
  .cs-form-group:not(.cs-form-group--nobox) .cs-input-group-btn > .tw-cs-btn {
    @apply hover:tw-border-cs-shade-black;
  }
  .cs-input-group-btn:not(:last-child) > .tw-cs-btn {
    @apply tw-rounded-r-none tw--mr-px;
  }
  .cs-input-group-btn:not(:first-child) > .tw-cs-btn {
    @apply tw-rounded-l-none tw--ml-px;
  }
}
