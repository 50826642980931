// APP MAIN START
.app__main {
  display: flex;
  position: relative;
  height: 100%;
  min-height: 0;
  width: 100%;

  // mobile
  @media (max-width: 767.98px) {
    flex-direction: column;
  }
}
// APP MAIN END

// APP SUBNAV START
.app__sub-nav,
.app__sub-sub-nav {
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 0;

  .app__sub-nav__list,
  .app__sub-sub-nav__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .app__sub-nav__item > a,
    .app__sub-sub-nav__item > a {
      color: rgb(var(--cs-shade-500));
      display: block;
      flex: 0 0 auto;
      overflow: hidden;
      margin-right: 1rem;
      padding-bottom: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.selected {
        border-bottom: 2px solid rgb(var(--tw-cs-accent-color));
        color: rgb(var(--cs-shade-1000));
      }
    }
  }
  &.app__sub-nav {
    box-shadow: -3px 0 20px -2px rgb(var(--cs-shadow-color-200));
    z-index: 10;
    background-color: rgb(var(--cs-shade-white));

    .app__sub-nav__list {
      .app__sub-nav__item > div > span,
      .app__sub-nav__item > a {
        padding: 1rem;
        margin: 0;
        font-weight: lighter;
        line-height: 1rem;
        &:hover {
          background-color: rgb(var(--cs-shade-100));
          color: rgb(var(--cs-shade-700));
        }
        &.selected {
          margin-top: -2px;
          background-color: rgb(var(--cs-shade-white));
        }
      }
    }
  }
  &.app__sub-sub-nav {
    margin-bottom: 1rem;

    .app__sub-sub-nav__list {
      .app__sub-sub-nav__item > a {
        &:hover {
          color: rgb(var(--cs-shade-700));
          border-bottom: 2px solid rgb(var(--tw-cs-accent-color-dark));
        }
      }
    }
  }
}
// APP SUBNAV END

// APP VIEW START
.app__view {
  flex-grow: 0;
  flex-shrink: 1;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  position: relative;

  &.app__view--with-subnav {
    display: flex;
    flex-direction: column;
  }

  .app__content {
    flex-shrink: 1;
    flex-grow: 0;
    height: 100%;
    min-height: 0;
    overflow-y: auto; // fallback
    overflow-y: overlay;
    position: relative;

    .app__content__container {
      max-width: 1920px;
      padding: 1rem;
    }
  }
}
// APP VIEW END

// APP CONTENTMINILISTCONTAINER START
.app__contentminilist__container {
  display: flex;
  height: 100%;
  min-height: 0;

  .app__contentminilist__list {
    overflow-y: auto; // fallback
    overflow-y: overlay;
    height: 100%;
    min-height: 0;
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: -3px 0 20px -2px rgb(var(--cs-shadow-color-200));
    background-color: rgb(var(--cs-shade-white));
    width: 15rem;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  .app__contentminilist__content {
    width: 100%;
    overflow-y: auto; // fallback
    overflow-y: overlay;
    height: 100%;
    min-height: 0;
    padding: 1rem;

    &-container {
      max-width: 1920px;
    }
  }
}
// APP CONTENTMINILISTCONTAINER END

// CARD ACCENT COLOR START
.card.card-accent-color {
  border-color: rgb(var(--tw-cs-accent-color));
  border-width: 1px;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    display: block;
    background-color: rgb(var(--tw-cs-accent-color-dark));
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.07;
  }
}
// CARD ACCENT COLOR END

// HELPCENTER START
.helpcenter {
  iframe {
    max-width: 100%;
    height: 185px;
  }
  img {
    max-width: 100%;
  }
  h2 {
    font-size: 1rem;
  }
  ol {
    padding-left: 14px;
  }
  ul {
    padding-left: 16px;
  }
  table {
    border: none;
    max-width: 100%;
    display: block;
    tbody {
      display: block;
      overflow-y: scroll;
      overflow-x: auto;
      tr {
        border-bottom: 1px solid #1b1a1a;
      }
      tr:first-child {
        border-bottom: 2px solid #1b1a1a;
      }
      td {
        padding: 10px;
        border: none;
        min-width: 200px;
      }
    }
  }
}
// HELPCENTER END
