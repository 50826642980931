.media-thumbnail {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  justify-content: center;
  position: relative;
}
.media-thumbnail svg {
  z-index: 1;
}
.media-thumbnail--withicon {
  position: relative;
}
.media-thumbnail--withicon:after {
  background-color: #fff;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.media-thumbnail__title {
  bottom: 0.5rem;
  font-size: 0.66666rem;
  left: 0.5rem;
  overflow: hidden;
  position: absolute;
  right: 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.media-thumbnail--withicon .media-thumbnail__title {
  z-index: 1;
}
.media-thumbnail__preview {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.media-thumbnail__icon {
  color: #999;
  z-index: 1;
}
