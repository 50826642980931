.cs-form-switch {
  display: block;
  margin-bottom: 1rem;
  position: relative;
}
.cs-form-switch.cs-form-switch--nobox > label {
  color: rgb(var(--cs-shade-500));
  font-size: 0.8333333333rem;
  pointer-events: none;
  position: relative;
  transform: translateY(1.375rem);
  transition: transform 0.3s, font-size 0.3s;
  z-index: 4;
}
.cs-form-switch.cs-form-switch--nobox > .cs-form-control::placeholder,
.cs-form-switch.cs-form-switch--nobox > .cs-input-group > input::placeholder {
  color: transparent;
  transition: color 0.3s;
}
.cs-form-switch.cs-form-switch--nobox.cs-form-switch--filled > label,
.cs-form-switch.cs-form-switch--nobox.cs-form-switch--focused > label {
  font-size: 0.66666rem;
  pointer-events: normal;
  transform: none;
}
.cs-form-switch.cs-form-switch--nobox.cs-form-switch--focused
  > .cs-form-control::placeholder,
.cs-form-switch.cs-form-switch--nobox.cs-form-switch--focused
  > .cs-input-group
  > input::placeholder {
  color: rgb(var(--cs-shade-100));
}
.cs-form-switch.disabled .cs-form-switch__label {
  color: rgb(var(--cs-shade-500));
}
.cs-form-switch__switch {
  display: inline-block;
  height: calc(1.75rem + 2px);
  margin-right: 1rem;
  outline: none;
  position: relative;
  transform: scale(0.85);
  vertical-align: middle;
  width: calc(3.5rem - 4px);
}
.cs-form-switch__input {
  left: -99999px;
  position: absolute;
  top: -99999px;
}
.cs-form-switch__slider {
  background-color: rgb(var(--cs-shade-300));
  border-radius: calc(1.75rem + 2px);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.cs-form-switch__handle {
  background-color: rgb(var(--cs-shade-white));
  border-radius: 50%;
  transition: 0.4s;
}
.cs-form-switch__handle,
.cs-form-switch__off-inicator,
.cs-form-switch__on-inicator {
  bottom: 4px;
  height: calc(1.75rem - 6px);
  left: 4px;
  position: absolute;
  width: calc(1.75rem - 6px);
}
.cs-form-switch__off-inicator,
.cs-form-switch__on-inicator {
  color: transparent;
  line-height: calc(1.75rem - 6px);
  pointer-events: none;
  text-align: center;
}
.cs-form-switch__on-inicator:after {
  background-color: #fff;
  content: "";
  height: calc(1.75rem - 18px);
  left: calc(0.875rem - 4px);
  opacity: 0.5;
  position: absolute;
  top: 6px;
  width: 2px;
}
.cs-form-switch__off-inicator {
  transform: translateX(1.75rem) translateX(2px) translateX(-8px);
}
.cs-form-switch__off-inicator:after {
  border: 2px solid #fff;
  border-radius: 50%;
  content: "";
  height: calc(1.75rem - 18px);
  left: 6px;
  opacity: 0.5;
  position: absolute;
  top: 6px;
  width: calc(1.75rem - 18px);
}
.cs-form-switch__input:checked + .cs-form-switch__slider {
  background-color: rgb(var(--tw-cs-accent-color));
}
.cs-form-switch__input:focus + .cs-form-switch__slider {
  box-shadow: 0 0 1px rgb(var(--tw-cs-accent-color));
}
.cs-form-switch__input:checked
  + .cs-form-switch__slider
  .cs-form-switch__handle {
  transform: translateX(1.75rem) translateX(2px) translateX(-8px);
}
.cs-form-switch__label {
  line-height: calc(1.75rem + 2px);
}
