.cs-input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
}
.cs-input-group .cs-form-control {
  flex: 1 1 auto;
  margin-bottom: 0;
  position: relative;
  width: 1%;
  z-index: 2;
}
.cs-input-group .cs-form-control:active,
.cs-input-group .cs-form-control:focus,
.cs-input-group .cs-form-control:hover {
  z-index: 3;
}
.cs-input-group-addon,
.cs-input-group-btn,
.cs-input-group .cs-form-control {
  align-items: center;
  display: flex;
}
.cs-input-group-addon:not(:first-child):not(:last-child),
.cs-input-group-btn:not(:first-child):not(:last-child),
.cs-input-group .cs-form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.cs-input-group-addon,
.cs-input-group-btn {
  white-space: nowrap;
}
.cs-form-group--nobox .cs-input-group-addon {
  background-color: transparent;
  border-bottom-color: rgb(var(--cs-shade-500));
  border-left-color: transparent;
  border-radius: 0;
  border-right-color: transparent;
  border-top-color: transparent;
  display: none;
}
.cs-form-group--nobox .cs-input-group-addon:first-child {
  padding-left: 0;
}
.cs-form-group--nobox .cs-input-group-addon:last-child {
  padding-left: 0.25rem;
  padding-right: 0;
}
.cs-form-group--nobox.cs-form-group--filled .cs-input-group-addon,
.cs-form-group--nobox.cs-form-group--focused .cs-input-group-addon {
  display: block;
}
.cs-input-group-addon {
  background-color: rgb(var(--cs-shade-50));
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0.25rem;
  color: rgb(var(--cs-shade-500));
  font-size: 0.8333333333rem;
  font-weight: 200;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.25rem 0.75rem;
  text-align: center;
}
.cs-input-group-addon.cs-form-control-sm,
.cs-input-group-sm > .cs-input-group-addon {
  border-radius: 0.2rem;
  font-size: 0.66666rem;
  padding: 0.25rem 0.5rem;
}
.cs-input-group-addon.cs-form-control-lg,
.cs-input-group-lg > .cs-input-group-addon {
  border-radius: 0.3rem;
  font-size: 1.0416666667rem;
  padding: 0.5rem 1rem;
}
.cs-input-group-addon input[type="checkbox"],
.cs-input-group-addon input[type="radio"] {
  margin-top: 0;
}
.cs-input-group-addon:not(:last-child),
.cs-input-group-btn:not(:last-child) > .cs-dropdown-toggle,
.cs-input-group .cs-form-control:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.cs-input-group-addon:not(:last-child) {
  border-right: 0;
}
.cs-input-group-addon:not(:first-child),
.cs-input-group-btn:not(:first-child) > .cs-dropdown-toggle,
.cs-input-group .cs-form-control:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.cs-form-control + .cs-input-group-addon:not(:first-child) {
  border-left: 0;
}
.cs-input-group-btn {
  align-items: stretch;
  font-size: 0;
  position: relative;
  white-space: nowrap;
}
.cs-input-group-btn > .cs-dropdown-menu {
  margin-top: calc(1.75rem + 2px);
}
