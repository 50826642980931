@tailwind components;

@layer components {
  /* react-select components */
  .cs-react-select-container-NEW {
    @apply tw-leading-4;
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__value-container {
    @apply tw-pl-0;
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__control {
    @apply tw-rounded-none tw-border-solid tw-border-0 tw-border-b tw-border-cs-shade-500
      /* @apply dark:tw-bg-neutral-700 dark:tw-border-neutral-700 dark:hover:tw-border-neutral-500; */;
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__control--is-focused {
    @apply tw-border-tw-cs-accent-color hover:tw-border-tw-cs-accent-color tw-shadow-none;
    /* @apply dark:tw-border-neutral-400 dark:hover:tw-border-neutral-400 tw-shadow-none; */
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__menu {
    @apply tw-mt-0 tw-rounded-t-none tw-bg-cs-shade-white tw-z-50;
    /* @apply dark:tw-bg-neutral-700 dark:tw-border-neutral-600; */
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__option {
    @apply tw-text-cs-shade-900 hover:tw-bg-cs-shade-100;
    /* @apply dark:tw-text-neutral-200 dark:tw-bg-neutral-700 dark:hover:tw-bg-neutral-800; */
  }
  .cs-react-select-container-NEW
    .cs-react-select-NEW__option.cs-react-select-NEW__option--is-focused {
    @apply tw-bg-cs-shade-100;
  }
  .cs-react-select-container-NEW
    .cs-react-select-NEW__option.cs-react-select-NEW__option--is-selected {
    @apply tw-bg-tw-cs-accent-color tw-text-white;
  }
  /* .cs-react-select-container-NEW .cs-react-select-NEW__option--is-focused {
      @apply bg-neutral-200;
      @apply dark:tw-bg-neutral-800;
    } */
  .cs-react-select-container-NEW .cs-react-select-NEW__dropdown-indicator {
    @apply tw-transition-transform;
  }
  .cs-react-select-container-NEW
    .cs-react-select-NEW__control--menu-is-open
    .cs-react-select-NEW__dropdown-indicator {
    @apply tw-rotate-180;
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__indicator-separator {
    @apply tw-w-0;
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__input-container,
  .cs-react-select-container-NEW .cs-react-select-NEW__placeholder,
  .cs-react-select-container-NEW .cs-react-select-NEW__single-value {
    /* @apply tw-text-neutral-600; */
    /* @apply dark:tw-text-neutral-200; */
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__placeholder {
    @apply tw-pl-0 tw-pr-0 tw-text-transparent tw-transition-colors;
  }
  .cs-react-select-container-NEW
    .cs-react-select-NEW__control--is-focused
    .cs-react-select-NEW__placeholder {
    @apply tw-text-slate-300;
  }

  .cs-react-select-container-NEW .cs-react-select-NEW__multi-value {
    @apply tw-bg-cs-shade-100 tw-border-solid tw-border tw-border-cs-shade-200 tw-rounded;
  }
  .cs-react-select-container-NEW .cs-react-select-NEW__multi-value__remove {
    @apply hover:tw-bg-cs-danger-100 hover:tw-text-cs-danger-dark;
  }
}
