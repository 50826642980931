*,
:after,
:before {
  box-sizing: border-box;
}
html {
  font-size: 131.25%;
}
body {
  font-size: 0.83333rem;
}
#root,
body,
html {
  background-color: rgb(var(--cs-shade-100));
  color: rgb(var(--cs-shade-black));
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 200;
  height: 100%;
  line-height: 1.5;
  overflow: hidden;
}
#root {
  display: flex;
  flex-direction: column;
}
.clearfix {
  overflow: auto;
}
.clearfix:after {
  clear: both;
  content: "";
  display: table;
}
::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 100px;
  width: 6px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
}
:focus-visible:focus {
  outline-color: rgb(var(--cs-shade-400));
}
