@tailwind components;

@layer components {
  /* helper to react to NavLink active class */
  .active-navlink-tablelist-tw-helper .cs-tablelist-itemaction-btn-tw-helper {
    @apply tw-from-cs-shade-50 tw-via-cs-shade-50;
  }

  /* SHIMMER ITEM */
  .tw-cs-shimmer-item {
    @apply tw-animate-bg-shimmer;
    background-size: 1200px 100%;
    background-color: rgb(var(--cs-shade-100));
    background-image: linear-gradient(
      to right,
      rgb(var(--cs-shade-100)) 8%,
      rgb(240, 240, 240) 18%,
      rgb(var(--cs-shade-100)) 33%
    );
  }
}
