.steps-bar {
  background-color: rgb(var(--cs-shade-300));
  height: 1px;
  margin: 0 0 calc(20px + 1rem);
  position: relative;
  width: 100%;
}
.steps-bar:before {
  background-color: rgb(var(--tw-cs-accent-color));
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width 0.3s;
  width: 0;
}
.steps-bar--1:before {
  width: 0;
}
.steps-bar--2:before {
  width: 25%;
}
.steps-bar--3:before {
  width: 50%;
}
.steps-bar--4:before {
  width: 75%;
}
.steps-bar--5:before {
  width: 100%;
}
.steps-bar--6 {
  background-color: rgb(var(--tw-cs-accent-color));
}
.steps-bar-step {
  background-color: rgb(var(--cs-shade-300));
  height: 8px;
  transform: translateY(-50%);
  transition: background-color 0.3s 0.15s, transform 0.3s 0.15s;
  width: 8px;
  z-index: 1;
}
.steps-bar-step,
.steps-bar-step:before {
  border-radius: 50%;
  position: absolute;
  top: 50%;
}
.steps-bar-step:before {
  background-color: #fff;
  content: "";
  height: 2px;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s 0.15s;
  width: 2px;
}
.steps-bar-step:first-of-type {
  left: 0;
}
.steps-bar-step:nth-of-type(2) {
  left: calc(25% - 2px);
}
.steps-bar-step:nth-of-type(3) {
  left: calc(50% - 4px);
}
.steps-bar-step:nth-of-type(4) {
  left: calc(75% - 6px);
}
.steps-bar-step:nth-of-type(5) {
  left: calc(100% - 8px);
}
.steps-bar-step.current {
  background-color: rgb(var(--tw-cs-accent-color));
}
.steps-bar-step.current:before {
  opacity: 1;
}
.steps-bar-step.completed {
  background-color: rgb(var(--tw-cs-accent-color));
  transform: scale(2.5) translateY(-25%);
}
.steps-bar-step.completed:first-of-type {
  transform: scale(2.5) translate(2px, -25%);
}
.steps-bar-step.completed:last-of-type {
  transform: scale(2.5) translate(-2px, -25%);
}
.steps-bar-step.completed svg {
  opacity: 1;
}
.steps-bar-step svg {
  height: 5px;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s 0.15s;
  width: 5px;
  z-index: 1;
}
