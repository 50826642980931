h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
  line-height: 1.2;
  margin-bottom: 0.5rem;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.3333333333rem;
}
h3 {
  font-size: 1.1666666667rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.8333333333rem;
}
h6 {
  font-size: 0.6666666667rem;
}
.lead {
  font-size: 1.0416666667rem;
  font-weight: 300;
}
.display-4 {
  font-size: 2.3333333333rem;
  font-weight: 100;
  line-height: 1.2;
}
hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  margin-top: 1rem;
}
small {
  font-size: 0.66666rem;
  font-weight: 200;
}
.blockquote {
  font-size: 1.0416666667rem;
  margin-bottom: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}
a {
  color: rgb(var(--cs-shade-700));
  cursor: pointer;
  text-decoration: inherit;
}
.tw-text-cs-shade-white a {
  color: rgb(var(--cs-shade-white));
  text-decoration: underline;
  &:hover {
    color: rgb(var(--cs-shade-300));
    text-decoration: none;
  }
}
[role="button"],
button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-weight: inherit;
  text-align: inherit;
}
label {
  display: inline-block;
  font-size: 0.66666rem;
  font-weight: 100;
  line-height: 1rem;
  margin-bottom: 0;
}
input,
textarea {
  font-weight: 200;
}
strong {
  font-weight: 600;
}
blockquote {
  margin: 0 0 1rem;
}
address {
  font-style: inherit;
}
.event blockquote,
.tox-content-body blockquote {
  border-left: 2px solid rgb(var(--tw-cs-accent-color));
  font-size: 0.66666rem;
  padding-left: 1rem;
}
