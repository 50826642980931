.json-schema-form__item,
.json-schema-form__object {
  break-inside: avoid;
  page-break-inside: avoid;
}
.json-schema-form__item {
  position: relative;
}
.json-schema-form__item + .json-schema-form__object {
  break-before: column;
}
.json-schema-form__formgroup {
  margin-bottom: 0.5rem;
}
.json-schema-form__formgroup__content {
  position: relative;
}
.json-schema-form__formgroup__rmbutton {
  background-color: rgb(var(--cs-shade-50));
  border: 0 solid rgb(var(--cs-shade-100));
  border-radius: 50%;
  color: transparent;
  height: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  text-align: center;
  top: 0.7rem;
  width: 1.5rem;
}
.json-schema-form__formgroup__rmbutton svg {
  color: rgb(var(--cs-shade-200));
  height: 1.3rem !important;
  width: 1.3rem !important;
}
.json-schema-form__formgroup__rmbutton:hover {
  background-color: rgb(var(--cs-shade-white));
  color: #dc3545;
}
.json-schema-form__formgroup__rmbutton:hover svg {
  color: #dc3545;
}
.json-schema-form__formgroup__rmbutton:focus {
  outline: none;
}
.json-schema-form__object--table .json-schema-form__object {
  padding-bottom: 0;
  padding-top: 0;
}
.json-schema-form__object--table .json-schema-form__formgroup__rmbutton {
  z-index: 3;
}
.json-schema-form__object--table
  .json-schema-form__formgroup
  .json-schema-form__formgroup__label {
  display: none;
}
.json-schema-form__object--table
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content {
  border-bottom: 0;
  border-left: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.json-schema-form__object--table
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content {
  align-items: flex-end;
  display: flex;
}
.json-schema-form__object--table
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content
  .json-schema-form__item {
  flex: 1 1 500px;
}
.json-schema-form__object--table
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content.card-body {
  padding-right: 1.5rem;
}
.json-schema-form__object--table
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content
  .cs-form-group {
  margin-bottom: 0;
}
.json-schema-form__object--table
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__content
  .json-schema-form__formgroup__rmbutton {
  right: 0;
  top: 2.5rem;
}
.json-schema-form__object--collapsed .json-schema-form__formgroup__label,
.json-schema-form__object--uncollapsed .json-schema-form__formgroup__label {
  cursor: pointer;
}
.json-schema-form__object--collapsed .json-schema-form__formgroup__label:before,
.json-schema-form__object--uncollapsed
  .json-schema-form__formgroup__label:before {
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  content: "";
  display: inline-block;
  height: 9px;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
  transform: translateY(-2px) rotate(45deg);
  transition: transform 0.3s;
  width: 9px;
}
.json-schema-form__object--collapsed {
  height: 56px;
  overflow: hidden;
}
.json-schema-form__object--collapsed .json-schema-form__formgroup__content {
  border-bottom: 0 solid rgb(var(--cs-shade-black));
  border-left: 0 solid rgb(var(--cs-shade-black));
  border-right: 0 solid rgb(var(--cs-shade-black));
}
.json-schema-form__object--collapsed
  .json-schema-form__formgroup__label:before {
  transform: translateY(-2px) rotate(45deg);
}
.json-schema-form__object--uncollapsed
  .json-schema-form__formgroup__label:before {
  transform: translateY(2px) rotate(-135deg);
}
.json-schema-form__object--table .json-schema-form__object--collapsed,
.json-schema-form__object--table .json-schema-form__object--uncollapsed {
  height: auto;
  overflow: visible;
}
.json-schema-data__root {
  column-count: 2;
}
.json-schema-data__field {
  break-inside: avoid;
  margin-bottom: 0.5rem;
  page-break-inside: avoid;
}
.json-schema-data__fieldlabel--heading {
  color: rgb(var(--tw-cs-accent-color));
  margin-bottom: 0.3rem;
}
.json-schema-data__field--array {
  border-left: 3px dotted rgb(var(--cs-shade-200));
  padding-left: 0.5rem;
}
.json-schema-data__field--object {
  border-left: 3px solid rgb(var(--tw-cs-accent-color));
  padding-left: 0.5rem;
}
.json-schema-data__field--table {
  border-left: 0 solid rgb(var(--tw-cs-accent-color));
  padding-left: 0;
}
.json-schema-data__field--table > .json-schema-data__sub-fields {
  border-left: 3px solid rgb(var(--tw-cs-accent-color));
  display: block;
}
.json-schema-data__field--table
  > .json-schema-data__sub-fields
  > .json-schema-data__field {
  margin-bottom: 0;
  padding: 0.5rem 0.5rem 0;
}
.json-schema-data__field--table
  > .json-schema-data__sub-fields
  > .json-schema-data__field
  .json-schema-data__fieldlabel {
  display: none;
}
.json-schema-data__field--table .json-schema-data__field--array {
  border: 0 solid gray;
  padding: 0;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  .json-schema-data__fieldlabel {
  display: none;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  > .json-schema-data__sub-fields {
  display: block;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  > .json-schema-data__sub-fields
  .json-schema-data__field {
  margin-bottom: 0;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  > .json-schema-data__sub-fields
  .json-schema-data__field
  .json-schema-data__field--field {
  display: flex;
  flex-direction: column;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  > .json-schema-data__sub-fields
  .json-schema-data__field--object {
  border-left: 0 solid rgb(var(--cs-shade-200));
  padding-left: 0;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  > .json-schema-data__sub-fields
  .json-schema-data__sub-fields {
  display: flex;
}
.json-schema-data__field--table
  .json-schema-data__field--array
  > .json-schema-data__sub-fields
  .json-schema-data__sub-fields
  .json-schema-data__field {
  border: 1px solid rgb(var(--cs-shade-200));
  flex: 1 1 10rem;
  padding: 0.3rem;
}
.json-schema-data__field--multitable
  .json-schema-data__field--table
  .json-schema-data__fieldlabel {
  display: none;
}
