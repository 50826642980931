.entity-chooser__wrapper {
  display: flex;
  flex-direction: column;
}
.entity-chooser__navRow {
  background-color: rgb(var(--cs-shade-white));
  border-bottom: 1px solid rgb(var(--cs-shade-100));
  display: none;
  padding: 0.5rem 1rem 0;
}
.entity-chooser__navMenu {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-left: -0.5rem;
  padding-left: 0;
}
.entity-chooser__navMenuItem {
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  margin-bottom: -1px;
  padding: 0.5rem 1rem;
}
.entity-chooser__navMenuItem:focus,
.entity-chooser__navMenuItem:hover {
  border-color: rgb(var(--cs-shade-100)) rgb(var(--cs-shade-100))
    rgb(var(--cs-shade-100));
  border-bottom-color: transparent;
}
.entity-chooser__navMenuItem:focus {
  outline: none;
}
.entity-chooser__activeNavMenuItem {
  background-color: rgb(var(--cs-shade-white));
  border-bottom-color: transparent;
  border-left-color: rgb(var(--cs-shade-200));
  border-right-color: rgb(var(--cs-shade-200));
  border-top-color: rgb(var(--cs-shade-200));
  color: rgb(var(--cs-shade-1000));
}
.entity-chooser__contentListRow {
  display: none;
  flex-grow: 1;
}
.entity-chooser__activeContentListRow {
  display: flex;
  opacity: 1;
}
.entity-chooser__listContentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.entity-chooser__actionRow {
  background-color: rgb(var(--cs-shade-white));
  margin-top: auto;
  overflow: hidden;
  padding: 0.5rem 1rem;
}
.entity-chooser__chosenActions {
  margin-right: 0.5rem;
}
.entity-chooser__chosenText {
  font-size: 16;
  font-weight: 700;
  line-height: 1rem;
}
.entity-chooser__chosenClearBtn {
  background-color: transparent;
  border: 0;
  color: #dc3545;
  font-size: 14;
  height: 1rem;
  line-height: 1rem;
  padding: 0;
}
.entity-chooser__chosenList {
  list-style: none;
  margin: 0;
}
.entity-chooser__chosenImg {
  background-color: rgb(var(--cs-shade-50));
  margin-left: 5px;
  margin-right: 5px;
}
.entity-chooser__contentUploadRow {
  display: none;
  flex-grow: 1;
  padding: 1rem;
  position: relative;
}
.entity-chooser__activeContentUploadRow {
  display: block;
  opacity: 1;
}
