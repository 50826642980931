.app__nav {
  background-color: rgb(var(--cs-shade-50));
  box-shadow: -3px 0 20px -2px rgb(var(--cs-shadow-color-200));
  color: rgb(var(--cs-shade-500));
  position: relative;
  transition: width 0.25s ease, transform 0.25s;
  width: 12rem;
  z-index: 11;
}
@media (max-width: 767.98px) {
  .app__nav {
    background-color: rgb(var(--cs-shade-50));
    box-shadow: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-100%);
  }
  .app__nav.open {
    transform: translateX(0);
  }
}
.app__nav-toggler {
  border-bottom: 1px solid rgb(var(--cs-shade-200));
  color: rgb(var(--cs-shade-500));
  cursor: pointer;
  display: block;
  font-weight: lighter;
  line-height: 1rem;
  margin: 0;
  overflow: hidden;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-overflow: ellipsis;
  transition: width 0.25s ease, margin 0.25s ease;
  white-space: nowrap;
  width: 12rem;
}
.app__nav-toggler svg {
  margin-left: 1rem;
  margin-right: 1rem;
  transition: all 0.5s;
}
.app__nav-toggler:focus {
  outline: none;
}
.app__nav-toggler:hover {
  background-color: rgb(var(--cs-shade-50));
  text-decoration: none;
}
.app__nav-list {
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  list-style: none;
  margin: 0.5rem 0 0;
  overflow: visible;
  padding: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .app__nav-list {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }
}
.app__nav-item > a,
.app__nav-item > button {
  background-color: rgb(var(--cs-shade-50));
  border-radius: 0;
  color: rgb(var(--cs-shade-500));
  cursor: pointer;
  display: block;
  font-weight: lighter;
  line-height: 1rem;
  margin: 0;
  overflow: hidden;
  padding: 1rem 0;
  text-align: left;
  transition: width 0.25s ease, margin 0.25s ease;
  white-space: nowrap;
  width: 12rem;
}
@media (max-width: 767.98px) {
  .app__nav-item > a,
  .app__nav-item > button {
    color: rgb(var(--cs-shade-700));
    padding: 0.6rem 0;
  }
}
.app__nav-item > a svg,
.app__nav-item > button svg {
  margin-left: 1rem;
  margin-right: 1.5rem;
}
.app__nav-item > a.selected,
.app__nav-item > button.selected {
  background: rgb(var(--cs-shade-white));
  border-right: 2px solid rgb(var(--tw-cs-accent-color));
}
@media (max-width: 767.98px) {
  .app__nav-item > a.selected,
  .app__nav-item > button.selected {
    border-bottom: 2px solid rgb(var(--tw-cs-accent-color));
    margin-bottom: -2px;
  }
}
.app__nav-item > a.selected svg,
.app__nav-item > button.selected svg {
  color: rgb(var(--tw-cs-accent-color));
}
.app__nav-item > a:focus,
.app__nav-item > button:focus {
  outline: none;
}
.app__nav-item > a:focus,
.app__nav-item > a:hover,
.app__nav-item > a[aria-expanded="true"],
.app__nav-item > button:focus,
.app__nav-item > button:hover,
.app__nav-item > button[aria-expanded="true"] {
  background-color: rgb(var(--cs-shade-100));
  color: rgb(var(--cs-shade-700));
  text-decoration: none;
}
.app__nav--collapsed {
  width: 3rem;
}
@media (max-width: 767.98px) {
  .app__nav--collapsed {
    width: 100%;
  }
}
.app__nav--collapsed .app__nav-item {
  width: auto;
}
.app__nav--collapsed .app__nav-item > a,
.app__nav--collapsed .app__nav-item > button {
  transition: width 0.2s ease;
  width: 3rem;
}
@media (min-width: 768px) {
  .app__nav--collapsed .app__nav-item > a:hover,
  .app__nav--collapsed .app__nav-item > a[aria-expanded="true"],
  .app__nav--collapsed .app__nav-item > button:hover,
  .app__nav--collapsed .app__nav-item > button[aria-expanded="true"] {
    margin-right: -9rem;
    width: 12rem;
  }
}
@media (max-width: 767.98px) {
  .app__nav--collapsed .app__nav-item > a,
  .app__nav--collapsed .app__nav-item > button {
    width: 100%;
  }
}
.app__nav--collapsed .app__nav-item > a.selected,
.app__nav--collapsed .app__nav-item > button.selected {
  background-color: rgb(var(--cs-shade-white));
}
.app__nav--collapsed .app__nav-toggler {
  color: rgb(var(--cs-shade-700));
  display: block;
  text-overflow: unset;
  width: 3rem;
}
.app__nav--collapsed .app__nav-toggler > a,
.app__nav--collapsed .app__nav-toggler > button {
  width: 3rem;
}
@media (max-width: 767.98px) {
  .app__nav--collapsed .app__nav-toggler {
    display: none;
  }
}
