@import "./components/btn-group.css";
@import "./components/btn.css";
@import "./components/card.css";
@import "./components/code.css";
@import "./components/boarding-js.css";
@import "./components/input-group.css";
@import "./components/modal.css";
@import "./components/tablelist.css";
@import "./components/tinymce.css";

@import "./components/react-select.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

:root {
  /* primary colors */
  --tw-cs-accent-color-light: 226 247 252; /* #e2f7fc */
  --tw-cs-accent-color: 21 166 202; /* #15a6ca */
  --tw-cs-accent-color-dark: 17 138 167; /* #118aa7 */
  /* shades */
  --cs-shade-white: 255 255 255; /* #ffffff */
  --cs-shade-black: 0 0 0; /* #000000 */
  --cs-shade-50: 250 250 250; /* #fafafa */
  --cs-shade-100: 245 245 245; /* #f5f5f5 */
  --cs-shade-200: 222 226 230; /* #dee2e6 */
  --cs-shade-300: 206 207 208; /* #cecfd0 */
  --cs-shade-400: 153 153 153; /* #999999 */
  --cs-shade-500: 137 147 163; /* #8993a3 */
  --cs-shade-600: 108 117 125; /* #6c757d */
  --cs-shade-700: 74 74 74; /* #4a4a4a */
  --cs-shade-800: 62 62 62; /* #3e3e3e */
  --cs-shade-900: 52 58 64; /* #343a40 */
  --cs-shade-1000: 33 37 41; /* #212529 */
  /* alert colors */
  --cs-info: 23 162 184; /* #17a2b8 */
  --cs-info-100: 209 236 241; /* #d1ecf1 */
  --cs-info-200: 190 229 235; /* #bee5eb */
  --cs-info-300: 12 84 96; /* #0c5460 */
  --cs-success: 40 167 69; /* #28A745 */
  --cs-success-dark: 33 136 56; /* #218838 */
  --cs-success-100: 212 237 218; /* #d4edda */
  --cs-success-200: 195 230 203; /* #c3e6cb */
  --cs-success-300: 21 87 36; /* #155724 */
  --cs-warning: 255 193 7; /* #ffc107 */
  --cs-warning-100: 255 243 205; /* #fff3cd */
  --cs-warning-200: 255 238 186; /* #ffeeba */
  --cs-warning-300: 133 100 4; /* #856404 */
  --cs-danger: 220 53 69; /* #dc3545 */
  --cs-danger-dark: 200 35 51; /* #c82333 */
  --cs-danger-100: 248 215 218; /* #f8d7da */
  --cs-danger-200: 245 198 203; /* #f5c6cb */
  --cs-danger-300: 114 28 36; /* #721c24 */
  /* shadow colors */
  --cs-shadow-color-100: 137 147 163; /* #8993A3 */
  --cs-shadow-color-200: 108 117 125; /* #6c757d */
  /* sticky note colors */
  --cs-sticky-note-100: 255 255 224; /* #ffffe0 */
  --cs-sticky-note-200: 219 219 171; /* #dbdbab */
  --cs-sticky-note-300: 145 145 91; /* #91915b */
}

body.cs-grabbing-cursor-all * {
  cursor: grabbing !important;
}
