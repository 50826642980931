.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}
.PresetDateRangePicker_button {
  background: 0 0;
  border: 2px solid rgb(var(--cs-shade-500));
  box-sizing: border-box;
  color: rgb(var(--cs-shade-500));
  cursor: pointer;
  font: inherit;
  font-weight: 700;
  height: 100%;
  line-height: normal;
  margin-right: 8px;
  overflow: visible;
  padding: 4px 12px;
  position: relative;
  text-align: center;
}
.PresetDateRangePicker_button:active {
  outline: 0;
}
.PresetDateRangePicker_button__selected {
  background: rgb(var(--cs-shade-500));
  color: rgb(var(--cs-shade-white));
}
.SingleDatePickerInput {
  align-items: center;
  background-color: rgb(var(--cs-shade-white));
  display: block;
  display: flex;
  justify-content: space-between;
}
.SingleDatePickerInput__withBorder {
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0.25rem;
}
.SingleDatePickerInput__rtl {
  direction: rtl;
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}
.SingleDatePickerInput__block {
  display: block;
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  margin: 0 10px 0 5px;
  overflow: visible;
  padding: 10px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  vertical-align: middle;
  width: 15px;
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: normal;
  margin: 0 5px 0 10px;
  overflow: visible;
  padding: 7px;
  vertical-align: middle;
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  vertical-align: middle;
  width: 14px;
}
.SingleDatePicker {
  display: block;
  position: relative;
  width: 100%;
}
.SingleDatePicker__block {
  display: block;
}
.SingleDatePicker_picker {
  background-color: rgb(var(--cs-shade-white));
  position: absolute;
  z-index: 5;
}
.SingleDatePicker_picker__rtl {
  direction: rtl;
}
.SingleDatePicker_picker__directionLeft {
  left: 0;
}
.SingleDatePicker_picker__directionRight {
  right: 0;
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: rgb(var(--cs-shade-white));
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}
.SingleDatePicker_closeButton_svg {
  fill: #cacccd;
  height: 15px;
  width: 15px;
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-size: 14px;
  line-height: normal;
  overflow: visible;
  padding: 0;
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_show {
  position: absolute;
  width: 22px;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  border-right: 33px solid rgb(var(--cs-shade-500));
  border-top: 26px solid transparent;
  bottom: 0;
  right: 0;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover {
  border-right: 33px solid rgb(var(--tw-cs-accent-color));
}
.DayPickerKeyboardShortcuts_show__topRight {
  border-bottom: 26px solid transparent;
  border-right: 33px solid rgb(var(--cs-shade-500));
  right: 0;
  top: 0;
}
.DayPickerKeyboardShortcuts_show__topRight:hover {
  border-right: 33px solid rgb(var(--tw-cs-accent-color));
}
.DayPickerKeyboardShortcuts_show__topLeft {
  border-bottom: 26px solid transparent;
  border-left: 33px solid rgb(var(--cs-shade-500));
  left: 0;
  top: 0;
}
.DayPickerKeyboardShortcuts_show__topLeft:hover {
  border-left: 33px solid rgb(var(--tw-cs-accent-color));
}
.DayPickerKeyboardShortcuts_showSpan {
  color: rgb(var(--cs-shade-white));
  position: absolute;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: -28px;
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  right: -28px;
  top: 1px;
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  left: -28px;
  top: 1px;
}
.DayPickerKeyboardShortcuts_panel {
  background: rgb(var(--cs-shade-white));
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  bottom: 0;
  left: 0;
  margin: 33px;
  overflow: auto;
  padding: 22px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.DayPickerKeyboardShortcuts_list {
  font-size: 14px;
  list-style: none;
  padding: 0;
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}
.DayPickerKeyboardShortcuts_closeSvg {
  fill: #cacccd;
  height: 15px;
  width: 15px;
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}
.CalendarDay:active {
  outline: 0;
}
.CalendarDay__defaultCursor {
  cursor: default;
}
.CalendarDay__default {
  background: rgb(var(--cs-shade-white));
  border: 1px solid rgb(var(--cs-shade-100));
}
.CalendarDay__default:hover {
  background: rgb(var(--cs-shade-100));
}
.CalendarDay__default:hover,
.CalendarDay__hovered_offset {
  border: 1px double rgb(var(--cs-shade-100));
  color: inherit;
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
}
.CalendarDay__outside {
  background: rgb(var(--cs-shade-white));
  border: 0;
  color: rgb(var(--cs-shade-700));
}
.CalendarDay__outside:hover {
  border: 0;
}
.CalendarDay__blocked_minimum_nights {
  border: 1px solid #eceeee;
}
.CalendarDay__blocked_minimum_nights,
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: rgb(var(--cs-shade-white));
  color: #cacccd;
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
}
.CalendarDay__today {
  background: rgb(var(--cs-shade-200));
  border: 1px solid rgb(var(--cs-shade-200));
}
.CalendarDay__selected_span {
  background: rgb(var(--tw-cs-accent-color-light));
  border: 1px solid rgb(var(--cs-shade-50));
  color: rgb(var(--cs-shade-400));
}
@media (prefers-color-scheme: dark) {
  .CalendarDay__selected_span {
    background: rgb(var(--tw-cs-accent-color-dark));
  }
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: rgb(var(--cs-shade-50));
  border: 1px solid rgb(var(--cs-shade-50));
  color: rgb(var(--cs-shade-white));
}
.CalendarDay__last_in_range {
  border-right: rgb(var(--cs-shade-500));
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: rgb(var(--tw-cs-accent-color));
  border: 1px solid rgb(var(--tw-cs-accent-color));
  color: rgb(var(--cs-shade-white));
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: rgb(var(--cs-shade-50));
  border: 1px solid rgb(var(--cs-shade-300));
  color: #007a87;
}
.CalendarDay__hovered_span:active {
  background: rgb(var(--cs-shade-300));
  border: 1px solid rgb(var(--cs-shade-300));
  color: #007a87;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: rgb(var(--cs-shade-white));
  border: 1px solid rgb(var(--cs-shade-100));
  color: #cacccd;
}
.CalendarMonth {
  background: rgb(var(--cs-shade-white));
  padding: 0 13px;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: top;
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}
.CalendarMonth_caption {
  caption-side: top;

  font-size: 18px;
  padding-bottom: 37px;
  padding-top: 22px;
  text-align: center;
}
.CalendarMonth_caption__verticalScrollable {
  padding-bottom: 7px;
  padding-top: 12px;
}
.CalendarMonthGrid {
  background: rgb(var(--cs-shade-white));
  text-align: left;
  z-index: 0;
}
.CalendarMonthGrid__animating {
  z-index: 1;
}
.CalendarMonthGrid__horizontal {
  left: 9px;
  position: absolute;
}
.CalendarMonthGrid__vertical {
  margin: 0 auto;
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  min-height: 100%;
  vertical-align: top;
}
.CalendarMonthGrid_month__hideForAnimation {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: -1;
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}
.DayPickerNavigation {
  position: relative;
  z-index: 2;
}
.DayPickerNavigation__verticalDefault {
  bottom: 0;
  height: 52px;
  left: 0;
  position: absolute;
  width: 100%;
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}
.DayPickerNavigation_button {
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  user-select: none;
}
.DayPickerNavigation_button__default {
  background-color: rgb(var(--cs-shade-white));
  border: 1px solid rgb(var(--cs-shade-100));
  color: #757575;
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid rgb(var(--cs-shade-400));
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}
.DayPickerNavigation_button__horizontalDefault {
  border-radius: 3px;
  line-height: 0.78;
  padding: 6px 9px;
  position: absolute;
  top: 18px;
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}
.DayPickerNavigation_button__verticalDefault {
  background: rgb(var(--cs-shade-white));
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  display: inline-block;
  height: 100%;
  padding: 5px;
  position: relative;
  width: 50%;
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%;
}
.DayPickerNavigation_svg__horizontal {
  fill: #82888a;
  height: 19px;
  width: 19px;
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
}
.DayPicker {
  position: relative;
  text-align: left;
}
.DayPicker,
.DayPicker__horizontal {
  background: rgb(var(--cs-shade-white));
}
.DayPicker__verticalScrollable {
  height: 100%;
}
.DayPicker__hidden {
  visibility: hidden;
}
.DayPicker__withBorder {
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  left: 50%;
  position: absolute;
  top: 50%;
}
.DayPicker_portal__vertical {
  position: static;
}
.DayPicker_focusRegion {
  outline: 0;
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}
.DayPicker_weekHeaders {
  position: relative;
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}
.DayPicker_weekHeader {
  color: #757575;
  padding: 0 13px;
  position: absolute;
  text-align: left;
  top: 62px;
  z-index: 2;
}
.DayPicker_weekHeader__vertical {
  left: 50%;
}
.DayPicker_weekHeader__verticalScrollable {
  background: rgb(var(--cs-shade-white));
  border-bottom: 1px solid #dbdbdb;
  display: table-row;
  left: 0;
  margin-left: 0;
  text-align: center;
  top: 0;
  width: 100%;
}
.DayPicker_weekHeader_ul {
  font-size: 14px;
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center;
}
.DayPicker_transitionContainer {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}
.DayPicker_transitionContainer__horizontal {
  transition: height 0.2s ease-in-out;
}
.DayPicker_transitionContainer__vertical {
  width: 100%;
}
.DayPicker_transitionContainer__verticalScrollable {
  bottom: 0;
  height: 100%;
  left: 0;
  overflow-y: scroll;
  padding-top: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.DateInput {
  background: transparent;
  display: inline-block;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
.DateInput__small {
  width: 97px;
}
.DateInput__block {
  width: 100%;
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb;
}
.DateInput_input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid transparent;

  font-size: 0.8333333333rem;
  font-weight: 200;
  line-height: 1.5;
  padding: 0.25rem 0.75rem;
  width: 100%;
}
.cs-form-group--nobox
  .DateRangePickerInput
  > .DateInput:first-child
  > .DateInput_input,
.cs-form-group--nobox
  .SingleDatePickerInput
  > .DateInput:first-child
  > .DateInput_input {
  padding-left: 0;
}
.DateInput_input__small {
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 18px;
  padding: 7px 7px 5px;
}
.DateInput_input__regular {
  font-weight: auto;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  user-select: none;
}
.DateInput_input__focused {
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgb(var(--tw-cs-accent-color));
  outline: 0;
}
.cs-form-group--nobox .SingleDatePickerInput .DateInput_input__focused {
  border-bottom: none;
}
.cs-form-group--nobox .SingleDatePickerInput .DateInput_input::placeholder {
  color: transparent;
  transition: color 0.3s;
}
.cs-form-group--nobox .SingleDatePickerInput .DateInput_input:hover {
  border-color: transparent;
}
.cs-form-group--focused.cs-form-group--nobox
  .SingleDatePickerInput
  .DateInput_input::placeholder {
  color: rgb(var(--cs-shade-100));
}
.DateInput_input:hover {
  border-color: #000;
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}
.DateInput_screenReaderMessage {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.DateInput_fang {
  height: 10px;
  left: 22px;
  position: absolute;
  width: 20px;
  z-index: 2;
}
.DateInput_fangShape {
  fill: rgb(var(--cs-shade-white));
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent;
}
.DateRangePickerInput {
  align-items: center;
  background-color: rgb(var(--cs-shade-white));
  display: block;
  display: flex;
  justify-content: space-between;
}
.cs-form-group--nobox .DateRangePickerInput,
.cs-form-group--nobox .SingleDatePickerInput {
  background-color: transparent;
}
.DateRangePickerInput__disabled {
  background: #f2f2f2;
}
.DateRangePickerInput__withBorder {
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0.25rem;
}
.cs-form-group--nobox .DateRangePickerInput__withBorder,
.cs-form-group--nobox .SingleDatePickerInput__withBorder {
  border-bottom-color: rgb(var(--cs-shade-500));
  border-left-color: transparent;
  border-radius: 0;
  border-right-color: transparent;
  border-top-color: transparent;
}
.DateRangePickerInput__rtl {
  direction: rtl;
}
.DateRangePickerInput__block {
  display: block;
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
}
.DateRangePickerInput_arrow_svg {
  height: 1rem;
  vertical-align: middle;
  width: 1rem;
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  margin: 0 10px 0 5px;
  overflow: visible;
  padding: 10px;
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.DateRangePickerInput_clearDates__small {
  padding: 6px;
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  vertical-align: middle;
  width: 15px;
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: normal;
  margin: 0 5px 0 10px;
  overflow: visible;
  padding: 7px;
  vertical-align: middle;
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  vertical-align: middle;
  width: 14px;
}
.DateRangePicker {
  display: block;
  position: relative;
  width: 100%;
}
.DateRangePicker__block {
  display: block;
}
.DateRangePicker_picker {
  background-color: rgb(var(--cs-shade-white));
  position: absolute;
  z-index: 5;
}
.DateRangePicker_picker__rtl {
  direction: rtl;
}
.DateRangePicker_picker__directionLeft {
  left: 0;
}
.DateRangePicker_picker__directionRight {
  right: 0;
}
.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: rgb(var(--cs-shade-white));
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none;
}
.DateRangePicker_closeButton_svg {
  fill: #cacccd;
  height: 15px;
  width: 15px;
}
