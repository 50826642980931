.cs-custom-control {
  display: inline-flex;
  margin-right: 0;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  position: relative;
}
.cs-input-group .cs-custom-control {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.cs-custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.cs-custom-control-input:checked ~ .cs-custom-control-indicator {
  background-color: rgb(var(--tw-cs-accent-color));
  color: #fff;
}
.cs-custom-control-input:focus ~ .cs-custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgb(var(--tw-cs-accent-color) / 0.25);
}
.cs-custom-control-input:active ~ .cs-custom-control-indicator {
  background-color: rgb(var(--tw-cs-accent-color-light));
  color: #fff;
}
.cs-custom-control-input:disabled ~ .cs-custom-control-indicator {
  background-color: rgb(var(--cs-shade-50));
}
.cs-custom-control-input:disabled ~ .cs-custom-control-description {
  color: rgb(var(--cs-shade-600));
}
.cs-custom-control-indicator {
  background-color: rgb(var(--cs-shade-100));
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  border: 1px solid rgb(var(--cs-shade-300));
  display: block;
  height: 1rem;
  left: 1px;
  pointer-events: none;
  position: absolute;
  -webkit-user-select: none;
  user-select: none;
  width: 1rem;
}
.cs-custom-checkbox .cs-custom-control-indicator {
  border-radius: 0.25rem;
}
.cs-custom-checkbox
  .cs-custom-control-input:checked
  ~ .cs-custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3E%3Cpath fill=%27%23fff%27 d=%27M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z%27/%3E%3C/svg%3E");
}
.cs-custom-checkbox
  .cs-custom-control-input:indeterminate
  ~ .cs-custom-control-indicator {
  background-color: rgb(var(--tw-cs-accent-color));
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 4 4%27%3E%3Cpath stroke=%27%23fff%27 d=%27M0 2h4%27/%3E%3C/svg%3E");
}
.cs-custom-radio .cs-custom-control-indicator {
  border-radius: 50%;
}
.cs-custom-radio
  .cs-custom-control-input:checked
  ~ .cs-custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%23fff%27/%3E%3C/svg%3E");
}
.cs-custom-controls-stacked {
  display: flex;
  flex-direction: column;
}
.cs-custom-controls-stacked .cs-custom-control {
  margin-bottom: 1rem;
}
.cs-custom-controls-stacked .cs-custom-control + .cs-custom-control {
  margin-left: 0;
}

$custom-select-indicator-color: rgb(var(--cs-shade-700));
$custom-select-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
  "#",
  "%23"
);

.cs-custom-select {
  -webkit-appearance: none;
  appearance: none;
  background: #fff $custom-select-indicator no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0.25rem;
  color: rgb(var(--cs-shade-100));
  display: inline-block;
  height: calc(1.75rem + 2px);
  line-height: 1.5;
  max-width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle;
}
.cs-custom-select:focus {
  border-color: rgb(var(--tw-cs-accent-color-light));
  outline: none;
}
.cs-custom-select:focus::-ms-value {
  background-color: rgb(var(--cs-shade-white));
  color: rgb(var(--cs-shade-100));
}
.cs-custom-select[multiple] {
  background-image: none;
  height: auto;
}
.cs-custom-select:disabled {
  background-color: rgb(var(--cs-shade-50));
  color: rgb(var(--cs-shade-600));
}
.cs-custom-select::-ms-expand {
  opacity: 0;
}
.cs-custom-select-sm {
  font-size: 75%;
  height: calc(1.49999rem + 2px);
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
}
.cs-custom-file {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
}
.cs-custom-file,
.cs-custom-file-input {
  height: calc(1.75rem + 2px);
  max-width: 100%;
}
.cs-custom-file-input {
  margin: 0;
  min-width: 5rem;
  opacity: 0;
}
.cs-custom-file-input:focus ~ .cs-custom-file-control {
  box-shadow: 0 0 0 0.2rem rgb(var(--tw-cs-accent-color) / 0.25);
}
.cs-custom-file-control {
  background-color: rgb(var(--cs-shade-white));
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0.25rem;
  left: 0;
  pointer-events: none;
  right: 0;
  top: 0;
  -webkit-user-select: none;
  user-select: none;
  z-index: 5;
}
.cs-custom-file-control,
.cs-custom-file-control:before {
  color: rgb(var(--cs-shade-100));
  height: calc(1.75rem + 2px);
  line-height: 1.5;
  padding: 0.25rem 0.75rem;
  position: absolute;
}
.cs-custom-file-control:before {
  background-color: rgb(var(--cs-shade-50));
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0 0.25rem 0.25rem 0;
  bottom: -1px;
  display: block;
  right: -1px;
  top: -1px;
  z-index: 6;
}
