.cs-mediaorganizer {
  width: 100%;
}
.cs-mediaorganizer__header {
  display: flex;
}
.cs-mediaorganizer__progress {
  min-width: 150px;
  padding: 5px;
}
.cs-mediaorganizer__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -0.5rem;
  padding-left: 0;
}
.cs-mediaorganizer__list--queue {
  border-top: 2px dashed rgb(var(--cs-shade-200));
  padding: 10px 0;
  text-align: center;
}
.cs-mediaorganizer__listitem {
  cursor: pointer;
  list-style: none;
  padding: 0.5rem;
  position: relative;
  width: 33.3333333%;
}
@media (min-width: 1200px) {
  .cs-mediaorganizer__listitem {
    width: 25%;
  }
}
.cs-mediaorganizer__listitem .cs-mediaorganizer__listitem__wrapper {
  transition: transform 0.5s ease;
}
.cs-mediaorganizer__listitem:hover .cs-mediaorganizer__listitem__remove {
  opacity: 0.5;
}
.cs-mediaorganizer__listitem:hover .cs-mediaorganizer__listitem__remove:hover {
  background-color: #dc3545;
  color: #fff;
  cursor: pointer;
  opacity: 1;
}
.cs-mediaorganizer__listitem:hover .cs-mediaorganizer__listitem__wrapper {
  transform: scale(1.1);
}
.cs-mediaorganizer__listitem__wrapper {
  background-color: rgb(var(--cs-shade-50));
  padding-top: 66.6666%;
  position: relative;
  width: 100%;
}
.cs-mediaorganizer__listitem__content {
  bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0.5rem;
  position: absolute;
  right: 0.5rem;
  text-align: center;
  top: 0.5rem;
}
.cs-mediaorganizer__listitem__remove {
  background-color: rgb(var(--cs-shade-white));
  border-radius: 50%;
  cursor: pointer;
  font-size: 1rem;
  line-height: 20px;
  opacity: 0.1;
  padding: 0;
  position: absolute;
  right: -5px;
  top: -5px;
  transition: all 0.2s ease;
}
.cs-mediaorganizer__listitem__title {
  font-size: 0.66666rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cs-mediaorganizer__listitem__icon {
  font-size: 1.5rem;
  line-height: 2rem;
  opacity: 0.5;
}
.cs-mediaorganizer__listitem__preview {
  background-position: 50%;
  background-size: cover;
  bottom: 0;
  left: 0;
  line-height: 100px;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.cs-mediaorganizer__listitem__preview img {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
}
.cs-mediaorganizer__sortableHelper {
  z-index: 100;
}
