@tailwind components;

@layer components {
  /* BTN COMPONENT */
  /* base */
  .tw-cs-btn-base {
    @apply tw-cursor-pointer tw-border tw-border-solid tw-inline-block tw-text-base tw-font-extralight tw-text-center tw-select-none tw-align-middle tw-whitespace-nowrap tw-transition tw-ease-in-out hover:tw-no-underline tw-py-1 tw-rounded tw-px-3;
    --tw-cs-btn-main-color: transparent;
    --tw-cs-btn-hover-color: transparent;
    --tw-cs-btn-text-color: inherit;
    --tw-cs-btn-text-hover-color: inherit;
    --tw-cs-btn-seperator-color: var(--tw-cs-btn-main-color);
    --tw-cs-btn-seperator-hover-color: var(--tw-cs-btn-hover-color);
    @apply tw-border-transparent tw-bg-[color:var(--tw-cs-btn-main-color)] tw-text-[color:var(--tw-cs-btn-text-color)] hover:tw-bg-[color:var(--tw-cs-btn-hover-color)] hover:tw-text-[color:var(--tw-cs-btn-text-hover-color)];
  }
  /* outline */
  .tw-cs-btn-outline {
    @apply tw-border tw-border-current tw-text-[color:var(--tw-cs-btn-main-color)] tw-bg-transparent hover:tw-bg-[color:var(--tw-cs-btn-main-color)] hover:tw-border-transparent;
  }
  /* disabled */
  .tw-cs-btn:disabled,
  .tw-cs-btn-disabled {
    @apply tw-opacity-60 tw-pointer-events-none;
  }
  /* active - base */
  .tw-cs-btn-active:not(.tw-cs-btn-outline) {
    @apply tw-bg-[color:var(--tw-cs-btn-hover-color)] tw-text-[color:var(--tw-cs-btn-text-hover-color)];
  }

  /* active - outline (same as usual "base") */
  .tw-cs-btn-outline.tw-cs-btn-active {
    @apply tw-border-transparent tw-bg-[color:var(--tw-cs-btn-main-color)] tw-text-[color:var(--tw-cs-btn-text-color)] hover:tw-bg-[color:var(--tw-cs-btn-hover-color)] hover:tw-text-[color:var(--tw-cs-btn-text-hover-color)];
  }
  /* small */
  .tw-cs-btn-small {
    @apply tw-py-1 tw-px-2 tw-text-sm tw-rounded-[0.2rem];
  }
  /* block */
  .tw-cs-btn-block {
    @apply tw-block tw-w-full;
  }
  /* link */
  .tw-cs-btn-link {
    @apply hover:tw-underline;
  }
  /* pill */
  .tw-cs-btn-pill {
    @apply tw-rounded-full tw-px-8;
  }
  /* header action */
  .tw-cs-btn-header-action {
    @apply tw-p-0 tw-pl-3 tw-text-[color:var(--tw-cs-btn-main-color)] tw-bg-transparent hover:tw-bg-transparent hover:tw-text-[color:var(--tw-cs-btn-hover-color)];
  }
  /* dropdown */
  .tw-cs-btn-dropdown {
    @apply tw-bg-transparent tw-text-left tw-w-full tw-block tw-clear-both tw-whitespace-nowrap tw-text-cs-shade-1000 hover:tw-bg-cs-shade-50 focus:tw-bg-tw-cs-accent-color focus:tw-text-cs-shade-white tw-py-1 tw-px-4;
  }
  /* dropdown toggle */
  .tw-cs-btn-dropdown-toggle {
    @apply after:tw-content-[''] after:tw-w-2 after:tw-h-2 after:tw-ml-2 after:empty:tw-ml-0 after:tw-mb-[0.2rem] after:tw-border after:tw-border-solid after:tw-inline-block after:tw-border-l-transparent after:tw-border-t-transparent after:tw-border-b-current after:tw-border-r-current after:tw-transition-all after:tw-rotate-45;
  }

  /* BTN COMPONENT THEMES */
  /* primary styles */
  .tw-cs-btn-primary {
    --tw-cs-btn-main-color: rgb(var(--tw-cs-accent-color));
    --tw-cs-btn-hover-color: rgb(var(--tw-cs-accent-color-dark));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-seperator-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-seperator-hover-color: rgb(var(--cs-shade-white));
  }
  /* secondary styles */
  .tw-cs-btn-secondary {
    --tw-cs-btn-main-color: rgb(var(--cs-shade-100));
    --tw-cs-btn-hover-color: rgb(var(--cs-shade-300));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-900));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-900));
  }
  /* light styles */
  .tw-cs-btn-light {
    --tw-cs-btn-main-color: rgb(var(--cs-shade-500));
    --tw-cs-btn-hover-color: rgb(var(--cs-shade-300));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
  }
  /* dark styles */
  .tw-cs-btn-dark {
    /* tw-bg-cs-shade-700 tw-border-cs-shade-700 tw-text-white hover:tw-bg-cs-shade-1000 hover:tw-border-cs-shade-1000; */
    --tw-cs-btn-main-color: rgb(var(--cs-shade-700));
    --tw-cs-btn-hover-color: rgb(var(--cs-shade-1000));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
  }
  /* success styles */
  .tw-cs-btn-success {
    --tw-cs-btn-main-color: rgb(var(--cs-success));
    --tw-cs-btn-hover-color: rgb(var(--cs-success-dark));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
  }
  /* danger styles */
  .tw-cs-btn-danger {
    --tw-cs-btn-main-color: rgb(var(--cs-danger));
    --tw-cs-btn-hover-color: rgb(var(--cs-danger-dark));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
  }
  /* warning styles */
  .tw-cs-btn-warning {
    --tw-cs-btn-main-color: rgb(var(--cs-warning));
    --tw-cs-btn-hover-color: rgb(var(--cs-warning));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
  }
  /* note styles */
  .tw-cs-btn-note {
    --tw-cs-btn-main-color: rgb(var(--cs-sticky-note-300));
    --tw-cs-btn-hover-color: rgb(var(--cs-sticky-note-300));
    --tw-cs-btn-text-color: rgb(var(--cs-shade-white));
    --tw-cs-btn-text-hover-color: rgb(var(--cs-shade-white));
  }
}
