.cs-form-control {
  background-clip: padding-box;
  background-color: rgb(var(--cs-shade-white));
  background-image: none;
  border: 1px solid rgb(var(--cs-shade-500));
  border-radius: 0.25rem;
  color: inherit;
  display: block;
  font-size: 0.8333333333rem;
  height: calc(1.75rem + 2px);
  line-height: 1.5;
  padding: 0.25rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .cs-form-control {
    transition: none;
  }
}
.cs-form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.cs-form-control:focus {
  outline: 0;
}
.cs-form-control::placeholder {
  color: rgb(var(--cs-shade-600));
  opacity: 1;
}
.cs-form-control:disabled,
.cs-form-control[readonly] {
  background-color: rgb(var(--cs-shade-50));
  opacity: 1;
}
select.cs-form-control:not([size]):not([multiple]) {
  height: calc(1.75rem + 2px);
}
select.cs-form-control:focus::-ms-value {
  background-color: rgb(var(--cs-shade-white));
  color: rgb(var(--cs-shade-100));
}
.cs-form-control-file,
.cs-form-control-range {
  display: block;
}
textarea.cs-form-control {
  height: auto;
}
.col-form-label {
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
}
.col-form-label--lg {
  font-size: 1.0416666667rem;
  line-height: 1.5;
  padding-bottom: calc(0.5rem + 1px);
  padding-top: calc(0.5rem + 1px);
}
.col-form-label--sm {
  font-size: 0.66666rem;
  line-height: 1.5;
  padding-bottom: calc(0.25rem + 1px);
  padding-top: calc(0.25rem + 1px);
}
.col-form-legend {
  font-size: 0.8333333333rem;
}
.col-form-legend,
.cs-form-control--plaintext {
  margin-bottom: 0;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}
.cs-form-control--plaintext {
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  line-height: 1.5;
}
.cs-form-control--plaintext.cs-form-control--lg,
.cs-form-control--plaintext.cs-form-control--sm {
  padding-left: 0;
  padding-right: 0;
}
.cs-form-control--sm {
  border-radius: 0.2rem;
  font-size: 0.66666rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
}
select.cs-form-control--sm:not([size]):not([multiple]) {
  height: calc(1.49999rem + 2px);
}
.cs-form-control--lg {
  border-radius: 0.3rem;
  font-size: 1.0416666667rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
}
select.cs-form-control--lg:not([size]):not([multiple]) {
  height: calc(2.5625rem + 2px);
}
.cs-form-group {
  margin-bottom: 1rem;
}
.cs-form-group:hover .cs-form-control {
  border-color: rgb(var(--cs-shade-700));
}
.cs-form-group:hover.cs-form-group--nobox .cs-form-control {
  border-bottom-color: rgb(var(--cs-shade-1000));
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
.cs-form-group.cs-form-group--nobox .cs-form-control {
  background-color: transparent;
}
.cs-form-group.cs-form-group--nobox .cs-form-control,
.cs-form-group.cs-form-group--nobox .cs-form-control--sm,
.cs-form-group.cs-form-group--nobox select.cs-form-control--sm {
  border-bottom-color: rgb(var(--cs-shade-500));
  border-left-color: transparent;
  border-radius: 0;
  border-right-color: transparent;
  border-top-color: transparent;
  padding: 0.25rem 2px;
}
.cs-form-group.cs-form-group--nobox > label {
  color: rgb(var(--cs-shade-500));
  font-size: 0.8333333333rem;
  pointer-events: none;
  position: relative;
  transform: translateY(1.375rem);
  transition: transform 0.3s, font-size 0.3s;
  z-index: 4;
}
.cs-form-group.cs-form-group--nobox > .cs-form-control::placeholder,
.cs-form-group.cs-form-group--nobox > .cs-input-group > input::placeholder {
  color: transparent;
  transition: color 0.3s;
}
.cs-form-group.cs-form-group--nobox.cs-form-group--alwaysfilled > label,
.cs-form-group.cs-form-group--nobox.cs-form-group--filled > label,
.cs-form-group.cs-form-group--nobox.cs-form-group--focused > label {
  font-size: 0.66666rem;
  pointer-events: normal;
  transform: none;
}
.cs-form-group.cs-form-group--nobox.cs-form-group--focused
  > .cs-form-control::placeholder,
.cs-form-group.cs-form-group--nobox.cs-form-group--focused
  > .cs-input-group
  > input::placeholder {
  color: rgb(var(--cs-shade-300));
}
.cs-form-group.cs-form-group--focused > label {
  color: rgb(var(--tw-cs-accent-color));
}
.cs-form-group.cs-form-group--focused .cs-form-control {
  border-color: rgb(var(--cs-shade-black));
}
.cs-form-group.cs-form-group--focused.cs-form-group--nobox .cs-form-control {
  border-bottom-color: rgb(var(--tw-cs-accent-color));
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
.cs-form-text {
  display: block;
  font-size: 0.66666rem;
  margin-top: 0.25rem !important;
}
.cs-form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.cs-form-row > .col,
.cs-form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}
.cs-form-check {
  display: block;
  margin-bottom: 1rem;
  position: relative;
}
.cs-form-check.disabled .cs-form-check__label {
  color: rgb(var(--cs-shade-500));
}
.cs-form-check__label {
  margin-bottom: 0;
  padding-left: 1.25rem;
}
.cs-form-check__input {
  margin-left: -1.25rem;
  margin-top: 0.3rem;
  position: absolute;
}
.cs-form-check--inline {
  display: inline-block;
  margin-right: 0.75rem;
}
.cs-form-check--inline .cs-form-check__label {
  vertical-align: middle;
}
:invalid {
  box-shadow: none;
}
:-moz-submit-invalid,
:-moz-ui-invalid {
  box-shadow: none;
}
.info-feedback {
  color: rgb(var(--cs-info));
  font-size: 0.66666rem;
  margin-top: 0.25rem;
  width: 100%;
}
.info-tooltip {
  background-color: rgba(23, 162, 184, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.66666rem;
  left: 0;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.form-row > .col > .info-tooltip,
.form-row > [class*="col-"] > .info-tooltip {
  left: 5px;
}
.valid-feedback {
  color: #28a745;
  font-size: 0.66666rem;
  margin-top: 0.25rem;
  width: 100%;
}
.valid-tooltip {
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.66666rem;
  left: 0;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}
.invalid-feedback {
  color: #dc3545;
  font-size: 0.66666rem;
  margin-top: 0.25rem;
  width: 100%;
}
.invalid-tooltip {
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
  color: #fff;
  display: none;
  font-size: 0.66666rem;
  left: 0;
  line-height: 1.5;
  margin-top: 0.1rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: 100%;
  z-index: 5;
}
.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}
.cs-form-inline {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.cs-form-inline .cs-form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .cs-form-inline label {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  .cs-form-inline .cs-form-group {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    margin-bottom: 0;
  }
  .cs-form-inline .cs-form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto;
  }
  .cs-form-inline .cs-form-control--plaintext {
    display: inline-block;
  }
  .cs-form-inline .cs-input-group {
    width: auto;
  }
  .cs-form-inline .cs-form-check {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 0;
    width: auto;
  }
  .cs-form-inline .cs-form-check__label {
    padding-left: 0;
  }
  .cs-form-inline .cs-form-check__input {
    margin-left: 0;
    margin-right: 0.25rem;
    margin-top: 0;
    position: relative;
  }
  .cs-form-inline .cs-custom-control {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
  .cs-form-inline .cs-custom-control-indicator {
    display: inline-block;
    margin-right: 0.25rem;
    position: static;
    vertical-align: text-bottom;
  }
  .cs-form-inline .has-feedback .cs-form-control-feedback {
    top: 0;
  }
}
.c-address-type-ahead__subfields {
  display: none;
}
.c-address-type-ahead__subfields--filled {
  display: block;
  z-index: 2;
}
.cs-form-group .Select-menu-outer {
  z-index: 10001;
}
.cs-form-controlinput:-webkit-autofill,
.cs-form-controlselect:-webkit-autofill,
.cs-form-controltextarea:-webkit-autofill {
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: inset 0 1px 1px transparent;
  color: rgb(var(--cs-shade-100)) !important;
}
.DayPicker .cs-form-group {
  margin-bottom: 0;
}
.DayPicker .Select-menu-outer {
  max-height: 280px;
}
.DayPicker .Select-menu {
  max-height: 278px;
}
