@tailwind components;

@layer components {
  .tinymceToolbarContainer
    .tox.tox-tinymce.tox-tinymce-inline
    .tox-editor-header {
    @apply tw-rounded;
  }
  .tox-editor-container {
    @apply tw-hidden;
  }
  .mce-content-body {
    @apply tw-border tw-border-solid tw-border-transparent tw-border-b-cs-shade-500 tw-min-h-[1rem] tw-pt-1 tw-pb-1;
  }
  .mce-content-body p:last-child,
  .mce-content-body ul:last-child {
    @apply tw-mb-0;
  }
  .cs-form-group.cs-form-group--focused .mce-content-body {
    @apply tw-border-b-tw-cs-accent-color;
  }
  .cs-form-group .mce-edit-focus {
    @apply tw-outline-none;
  }
  .tinymceToolbarContainer .tox .tox-menu .tox-collection__item-label {
    width: max-content;
  }
}
