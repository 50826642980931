.Select {
  text-align: left;
}
.cs-form-group.cs-form-group--nobox .Select__control {
  background-color: transparent;
  border-bottom-color: rgb(var(--cs-shade-500));
  border-left-color: transparent;
  border-radius: 0;
  border-right-color: transparent;
  border-top-color: transparent;
}
.cs-form-group.cs-form-group--nobox .Select:hover .Select__control {
  border-bottom-color: rgb(var(--cs-shade-900));
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
.cs-form-group.cs-form-group--nobox .Select__placeholder {
  color: transparent;
  padding-left: 0;
  padding-right: 0;
  transition: color 0.15s ease;
}
.cs-form-group.cs-form-group--nobox
  .Select__control--is-focused
  .Select__placeholder {
  color: rgb(var(--cs-shade-300));
}
.cs-form-group.cs-form-group--nobox .Select__input {
  padding-left: 0;
  padding-right: 0;
}
.cs-form-group.cs-form-group--nobox
  .Select--single
  > .Select-control
  .Select-value {
  padding-left: 0;
  padding-right: 1.6rem;
}
.cs-form-group.cs-form-group--nobox .Select__control--is-focused,
.cs-form-group.cs-form-group--nobox .Select__control--is-open {
  border-bottom-color: rgb(var(--cs-shade-900));
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: none;
}
.cs-form-group .Select__control,
.cs-form-group .Select__control--is-focused {
  background-color: rgb(var(--cs-shade-white));
}
.cs-form-group .Select__control--is-focused .Select__single-value,
.cs-form-group .Select__control .Select__single-value {
  color: inherit;
}
.cs-form-group .Select__menu {
  background-color: rgb(var(--cs-shade-white));
}
.cs-form-group .Select__menu .Select__option {
  color: rgb(var(--cs-shade-700));
}
.cs-form-group .Select__menu .Select__option.Select__option--is-focused,
.cs-form-group .Select__menu .Select__option.Select__option--is-selected,
.cs-form-group .Select__menu .Select__option:hover {
  background-color: rgb(var(--cs-shade-100));
  color: rgb(var(--cs-shade-700));
}
.Select__arrow {
  border: 0 transparent;
  border-top: 0 #999;
}
.Select__arrow:after {
  border-color: transparent currentcolor currentcolor transparent;
  border-style: solid;
  border-width: 1px;
  content: "";
  display: inline-block;
  height: 0.6em;
  margin-left: 0.45em;
  transform: rotate(45deg);
  transition: all 0.2s;
  vertical-align: 0.255em;
  width: 0.6em;
}
.Select__arrow:empty:after {
  margin-left: 0;
}
.is-open .Select__arrow:after {
  border-color: transparent currentcolor currentcolor transparent;
  border-style: solid;
  border-width: 1px;
  content: "";
  display: inline-block;
  height: 0.6em;
  margin-left: 0.45em;
  transform: rotate(45deg);
  transform: rotate(225deg);
  transition: all 0.2s;
  vertical-align: -0.255em;
  width: 0.6em;
}
.is-open .Select__arrow:empty:after {
  margin-left: 0;
}
.Select-menu-item__right {
  color: rgb(var(--cs-shade-500));
  float: right;
}
