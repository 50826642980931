.property-thumbnail {
  align-items: center;
  color: rgb(var(--cs-shade-1000));
  display: flex;
  flex-shrink: 0;
  font-size: 1.5rem;
  justify-content: center;
  position: relative;
}
.property-thumbnail svg {
  opacity: 0.4;
  z-index: 1;
}
.property-thumbnail:after {
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.property-thumbnail.property-thumbnail--withicon:after {
  background-color: rgb(var(--cs-shade-white));
  opacity: 0.1;
}
