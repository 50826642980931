.media-chooser__wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 11rem);
}
.media-chooser__navRow {
  background-color: rgb(var(--cs-shade-50));
  border-bottom: 1px solid rgb(var(--cs-shade-100));
  padding: 0.5rem 1rem 0;
}
.media-chooser__navMenu {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  margin-left: -0.5rem;
  padding-left: 0;
}
.media-chooser__navMenuItem {
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  margin-bottom: -1px;
  padding: 0.5rem 1rem;
}
.media-chooser__navMenuItem:focus,
.media-chooser__navMenuItem:hover {
  border-color: rgb(var(--cs-shade-100)) rgb(var(--cs-shade-100))
    rgb(var(--cs-shade-100));
  border-bottom-color: transparent;
}
.media-chooser__navMenuItem:focus {
  outline: none;
}
.media-chooser__activeNavMenuItem {
  background-color: rgb(var(--cs-shade-white));
  border-bottom-color: transparent;
  border-left-color: rgb(var(--cs-shade-200));
  border-right-color: rgb(var(--cs-shade-200));
  border-top-color: rgb(var(--cs-shade-200));
  color: rgb(var(--cs-shade-1000));
}
.media-chooser__contentListRow {
  display: none;
  flex-grow: 1;
}
.media-chooser__activeContentListRow {
  display: flex;
  opacity: 1;
}
.media-chooser__listContentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
}
.media-chooser__filter {
  border-bottom: 1px solid rgb(var(--cs-shade-50));
  display: flex;
  flex-basis: 3rem;
  height: 3rem;
  min-height: 3rem;
}
.media-chooser__filterItem {
  flex-grow: 1;
  height: 3rem;
  padding: 0.5rem;
}
.media-chooser__listWrapper {
  flex-basis: 0;
  flex-grow: inherit;
  max-height: 88vh;
  overflow: auto;
  padding: 1rem;
}
.media-chooser__listLoader {
  background-color: #000;
  border-radius: 3px;
  bottom: 5px;
  color: #fff;
  display: block;
  font-weight: 700;
  left: 5px;
  padding: 5px 0.5rem;
  right: 5px;
  text-align: center;
}
.media-chooser__actionRow {
  background-color: rgb(var(--cs-shade-white));
  flex: 0 0 3rem;
  height: 3rem;
  margin-top: auto;
  overflow: hidden;
  padding: 0.5rem 1rem;
}
.media-chooser__chosenActions {
  float: left;
  margin-right: 0.5rem;
}
.media-chooser__chosenText {
  font-size: 16;
  font-weight: 700;
  line-height: 1rem;
}
.media-chooser__chosenClearBtn {
  background-color: transparent;
  border: 0;
  color: #dc3545;
  font-size: 14;
  height: 1rem;
  line-height: 1rem;
  padding: 0;
}
.media-chooser__chosenList {
  list-style: none;
  margin: 0;
}
.media-chooser__chosenLi {
  float: left;
}
.media-chooser__chosenImg {
  background-color: rgb(var(--cs-shade-100));
  color: transparent;
  font-size: 0.1px;
  height: 2rem;
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  width: 2rem;
}
.media-chooser__chosenImg__img {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.media-chooser__chosenImg__key {
  color: #fff;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
}
.media-chooser__list {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
.media-chooser__listItem {
  background-color: rgb(var(--cs-shade-50));
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0.5rem;
  max-height: 150px;
  max-width: 110px;
  min-height: 100px;
  min-width: 100px;
}
.media-chooser__listItem--loading {
  animation: placeHolderShimmer 3s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
  cursor: not-allowed;
}
.media-chooser__listItemBox {
  cursor: pointer;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;
}
.media-chooser__listItem__content {
  border: 3px solid transparent;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.media-chooser__listItemBox--selected .media-chooser__listItem__content {
  border: 3px solid #28a745;
}
.media-chooser__listItemBox__selectedicon {
  color: #28a745;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.media-chooser__listItemBox__addicon,
.media-chooser__listItemBox__removeicon {
  color: #28a745;
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}
.media-chooser__listItemBox__removeicon {
  color: #000;
}
.media-chooser__listItemBox:hover .media-chooser__listItemBox__addicon,
.media-chooser__listItemBox:hover .media-chooser__listItemBox__removeicon {
  display: block;
}
.media-chooser__uploadErr {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  left: 0;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.media-chooser__uploadProgress {
  background-color: #fff;
  border: 2px solid rgb(var(--cs-shade-500));
  border-radius: 5px;
  bottom: 0.5rem;
  height: 0.5rem;
  left: 0.5rem;
  overflow: hidden;
  position: absolute;
  right: 0.5rem;
}
.media-chooser__uploadProgressBar {
  background-color: rgb(var(--cs-shade-300));
  height: 0.5rem;
  transition: width 0.2s linear;
}
.media-chooser__selection {
  background-color: rgb(var(--cs-shade-50));
  flex: 0 0 300px;
  overflow: auto;
  padding: 1rem;
}
.media-chooser__contentUploadRow {
  display: none;
  flex-grow: 1;
  padding: 1rem;
  position: relative;
}
.media-chooser__activeContentUploadRow {
  display: flex;
  opacity: 1;
}
.media-chooser__dropZone {
  align-items: center;
  border: 3px dotted rgb(var(--cs-shade-100));
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  transition: background 0.5s;
}
.media-chooser__dropZoneText {
  color: rgb(var(--cs-shade-500));
  font-size: 18;
  text-align: center;
}
