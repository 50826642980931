@tailwind components;

@layer components {
  kbd {
    @apply tw-bg-cs-shade-600 tw-rounded tw-text-cs-shade-100 tw-py-[0.1rem] tw-px-[0.2rem] tw-text-sm;
  }

  pre {
    @apply tw-block tw-text-sm;
  }
}
