@import "boarding.js/styles/main.css";
@import "boarding.js/styles/themes/basic.css";

@tailwind components;

@layer components {
  div#boarding-popover-item {
    @apply tw-max-w-[17rem];
  }

  div#boarding-popover-item.cs-boarding-popover {
    @apply tw-rounded tw-bg-cs-shade-white tw-p-3;
  }
  div#boarding-popover-item.cs-boarding-popover .boarding-popover-title {
    @apply -tw-mx-3 tw-px-3 tw-pb-2 tw-border-solid tw-border-b tw-border-transparent tw-border-0 tw-border-b-cs-shade-100 tw-mb-0 tw-font-light tw-text-tw-cs-accent-color tw-text-base;
  }
  div#boarding-popover-item.cs-boarding-popover .boarding-popover-description {
    @apply -tw-mx-3 tw-px-3 tw-py-2 tw-border-solid tw-border-b tw-border-transparent tw-border-0 tw-border-b-cs-shade-100 tw-font-light tw-text-sm;
  }
  div#boarding-popover-item.cs-boarding-popover
    .boarding-popover-footer
    .boarding-btn-group {
    @apply tw-flex;
  }
  div#boarding-popover-item.cs-boarding-popover
    .boarding-popover-footer
    button {
    @apply tw-inline-block tw-mr-1 last:tw-mr-0;
    @apply tw-border-none tw-rounded-sm tw-bg-tw-cs-accent-color tw-text-cs-shade-white tw-font-thin tw-text-sm tw-relative tw-px-3 tw-py-1;
    @apply hover:tw-bg-tw-cs-accent-color-dark tw-transition-colors tw-duration-200;
    @apply focus:tw-outline focus:tw-outline-2 tw-outline-cs-shade-300;
    text-shadow: none;
  }
  div#boarding-popover-item.cs-boarding-popover
    .boarding-popover-footer
    button.boarding-prev-btn {
    @apply tw-bg-transparent tw-text-tw-cs-accent-color tw-border tw-border-solid tw-border-tw-cs-accent-color hover:tw-text-white hover:tw-bg-tw-cs-accent-color;
  }
  div#boarding-popover-item.cs-boarding-popover
    .boarding-popover-footer
    button.boarding-close-btn {
    @apply tw-bg-cs-shade-100 tw-text-cs-shade-900;
    @apply hover:tw-bg-cs-shade-300;
  }
  div#boarding-popover-item.cs-boarding-popover
    .boarding-popover-footer
    button.boarding-disabled {
    @apply tw-opacity-50;
  }
}

@keyframes pulsatePopover {
  0%,
  100% {
    filter: drop-shadow(0px 0px 10px rgb(var(--cs-warning) / 0.3));
  }
  50% {
    filter: drop-shadow(0px 0px 15px rgb(var(--cs-warning) / 0.7));
  }
}

div#boarding-popover-item.cs-boarding-popover.cs-popover-awaiting-input {
  border: 3px solid rgb(var(--cs-warning));
  animation: pulsatePopover 2.5s infinite;
}

.cs-popover-awaiting-input div.boarding-popover-tip.boarding-tipside-bottom {
  filter: drop-shadow(0px -3px 0px rgb(var(--cs-warning)));
}
.cs-popover-awaiting-input div.boarding-popover-tip.boarding-tipside-top {
  filter: drop-shadow(0px 3px 0px rgb(var(--cs-warning)));
}
.cs-popover-awaiting-input div.boarding-popover-tip.boarding-tipside-left {
  filter: drop-shadow(3px 0px 0px rgb(var(--cs-warning)));
}
.cs-popover-awaiting-input div.boarding-popover-tip.boarding-tipside-right {
  filter: drop-shadow(-3px 0px 0px rgb(var(--cs-warning)));
}
