@tailwind components;

@layer components {
  /* CARD COMPONENT */
  /* base */
  .modal .tw-cs-card-base {
    @apply tw-shadow-sm;
  }
  .tw-cs-card-base {
    @apply tw-relative tw-flex tw-flex-col tw-min-w-0 tw-border;
    --tw-cs-card-border-color: rgb(var(--cs-shade-100));
    --tw-cs-card-bg-color: rgb(var(--cs-shade-white));
    @apply tw-bg-clip-border tw-border-[color:var(--tw-cs-card-border-color)] tw-border-solid tw-rounded tw-bg-[color:var(--tw-cs-card-bg-color)];
  }
  /* loading */
  .tw-cs-card-loading {
    @apply tw-cursor-not-allowed tw-animate-pulse-fast;
  }
  /* hasPrimaryBg */
  .tw-cs-card-primary-bg {
    --tw-cs-card-bg-color: rgb(var(--cs-shade-white));
    --tw-cs-card-border-color: rgb(var(--tw-cs-accent-color-dark));
    @apply tw-bg-[color:var(--tw-cs-card-bg-color)] tw-border-[color:var(--tw-cs-card-border-color)] tw-border tw-border-solid tw-z-0;
  }
  /* note */
  .tw-cs-card-note {
    @apply tw-shadow-none tw-border-none;
    --tw-cs-card-bg-color: rgb(var(--cs-sticky-note-100));
    @apply tw-bg-[color:var(--tw-cs-card-bg-color)];
  }

  /* CARD HEADER COMPONENT */
  /* base */
  .tw-cs-card-header-base {
    @apply tw-relative tw-text-base tw-mb-0 tw-border-b-2 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-py-3 tw-px-4 tw-rounded-t;
    --tw-cs-card-header-text-color: rgb(var(--tw-cs-accent-color));
    --tw-cs-card-header-border-color: rgb(var(--cs-shade-50));
    --tw-cs-card-header-bg-color: rgb(var(--cs-shade-white));
    @apply tw-text-[color:var(--tw-cs-card-header-text-color)] tw-bg-[color:var(--tw-cs-card-header-bg-color)] tw-border-[color:var(--tw-cs-card-header-border-color)];
  }
  /* note */
  .tw-cs-card-header-note {
    --tw-cs-card-header-text-color: rgb(var(--cs-sticky-note-300));
    --tw-cs-card-header-border-color: rgb(var(--cs-sticky-note-200));
    --tw-cs-card-header-bg-color: rgb(var(--cs-sticky-note-100));
    @apply tw-text-[color:var(--tw-cs-card-header-text-color)] tw-bg-[color:var(--tw-cs-card-header-bg-color)] tw-border-[color:var(--tw-cs-card-header-border-color)];
  }

  /* CARD BODY COMPONENT */
  /* base */
  .tw-cs-card-body-base {
    @apply tw-flex-auto tw-p-4 tw-relative;
  }
  /* isSmall */
  .tw-cs-card-body-is-small {
    @apply tw-text-sm;
  }
  /* hasMinHeight */
  .tw-cs-card-body-has-min-height {
    @apply tw-min-h-[6rem];
  }
  /* hasPrimaryBg */
  .tw-cs-card-body-has-primary-bg {
    --tw-cs-card-body-bg-color: rgb(var(--tw-cs-accent-color-dark) / 7%);
    @apply tw-bg-[color:var(--tw-cs-card-body-bg-color)];
  }
  /* note */
  .tw-cs-card-body-note {
    --tw-cs-card-header-text-color: rgb(var(--cs-sticky-note-300));
    @apply tw-text-[color:var(--tw-cs-card-header-text-color)];
  }
}
