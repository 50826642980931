.svgicon .icon {
  display: inline-block;
  height: 1em;
  width: 1em;
}
.svgicon-1x .icon {
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
}
.svgicon-1-5x .icon {
  display: inline-block;
  height: 2.25em;
  width: 2.25em;
}
.svgicon-2x .icon {
  display: inline-block;
  height: 3em;
  width: 3em;
}
.leaflet-marker-icon {
  font-size: 30px;
  height: 1em;
  width: 1em;
}

.spinner {
  border: 4px solid transparent;
  border-radius: 50%;
  height: 56px;
  margin: -28px auto 0;
  position: relative;
  top: 200px;
  width: 56px;
}
.spinner.small-spinner {
  transform: scale(0.5);
}
.spinner:before {
  animation: loader-scale 1s ease-out infinite;
  animation-delay: 1s;
  border: 4px solid rgb(var(--tw-cs-accent-color-light));
  border-radius: 50%;
  content: "";
  height: 64px;
  left: -8px;
  opacity: 0;
  position: absolute;
  top: -8px;
  width: 64px;
}
.spinner:after {
  animation: loader-scale 1s ease-out infinite;
  animation-delay: 0.5s;
  border: 4px solid rgb(var(--tw-cs-accent-color));
  border-radius: 50%;
  content: "";
  height: 56px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 56px;
}
@keyframes loader-scale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
.spinner-fixture {
  position: relative;
}
.spinner-fixture .spinner {
  left: 50%;
  margin-left: -28px;
  margin-top: -28px;
  position: absolute;
  top: 50%;
  z-index: 5;
}
