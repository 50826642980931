@tailwind components;

@layer components {
  /* BTN-GROUP COMPONENT > Button styles */
  .tw-cs-btn-group > .tw-cs-btn:not(:last-child) {
    @apply tw-rounded-r-none;
  }
  .tw-cs-btn-group > .tw-cs-btn:not(:first-child) {
    @apply tw-rounded-l-none;
  }
  .tw-cs-btn-group > .tw-cs-btn + .tw-cs-btn {
    @apply tw-relative tw-border-l-0 before:tw-content-[''] before:tw-block before:tw-absolute before:tw--top-px before:tw--left-px before:tw-w-px before:tw-h-[calc(100%+2px)];
    @apply before:tw-transition-all before:tw-bg-[color:var(--tw-cs-btn-seperator-color)] hover:before:tw-bg-[color:var(--tw-cs-btn-seperator-hover-color)];
  }
  .tw-cs-btn-group > .tw-cs-btn:hover + .tw-cs-btn {
    @apply before:tw-bg-[color:var(--tw-cs-btn-seperator-hover-color)];
  }
}
