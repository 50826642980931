@tailwind components;

@layer components {
  /* MODAL (+ MODAL LOADER) OVERLAY COMPONENT */
  .tw-cs-modal-loader-overlay[data-state="open"],
  .tw-cs-modal-overlay[data-state="open"] {
    @apply tw-animate-fade-in;
  }
  .tw-cs-modal-loader-overlay[data-state="closed"],
  .tw-cs-modal-overlay[data-state="closed"] {
    @apply tw-animate-fade-out;
  }

  /* MODAL CONTENT COMPONENT */
  .tw-cs-modal-content[data-state="open"] {
    @apply tw-animate-scale-up;
  }
  .tw-cs-modal-content[data-state="closed"] {
    @apply tw-animate-scale-down;
  }

  /* MODAL FOOTER COMPONENT */
  .tw-cs-modal-body .tw-cs-modal-footer {
    @apply tw--mx-4 tw--mb-4;
  }
}
