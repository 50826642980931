.data_protection {
  counter-reset: h2counter;
  font-size: 0.66666rem;
  font-weight: 200;
}
.data_protection ol {
  counter-reset: section;
  list-style-type: none;
  padding-left: 1rem;
}
.data_protection > ol {
  border: 1px solid rgb(var(--cs-shade-300));
  padding: 1rem;
}
.data_protection ol li {
  counter-increment: section;
}
.data_protection ol li:before {
  content: counters(section, ".") ". ";
}
.data_protection > h1 {
  counter-reset: h2counter;
}
.data_protection > h2 {
  counter-reset: h3counter;
}
.data_protection > h2.data_protection__index_header:before {
  content: "";
}
.data_protection > h2:before {
  content: counter(h2counter) ". ";
  counter-increment: h2counter;
}
.data_protection > h3:before {
  content: counter(h2counter) "." counter(h3counter) ". ";
  counter-increment: h3counter;
}
